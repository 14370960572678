@tailwind base;
@tailwind components;
@tailwind utilities;

body {
    @apply bg-grey-200;
}

@layer base {

    .spinner {
        @apply inline-block w-10 h-10 border-4 border-brand border-b-transparent rounded-full animate-spin;
    }

    .btn {
        @apply inline-flex items-center justify-center gap-2 h-10 px-4 bg-brand rounded-full shadow text-sm text-black font-bold transition-shadow active:shadow-none;
    }

    .btn > svg {
        @apply w-6 h-6 fill-black;
    }

    .nav-item {
        @apply inline-flex items-center h-8 px-2 text-inherit;
    }

    .social-btn {
        @apply inline-flex items-center justify-center w-8 h-8;
    }
}

.bg-gradient-main {
    @apply bg-gradient-to-r from-[#312F3E] to-[#6A4048] text-white;
}

.animate-popup {
    animation: popup 150ms linear;
}

@keyframes popup {
    from {
        transform: scale(0);
    }
    to {
        transform: scale(1);
    }
}
